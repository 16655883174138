
.NavBar .MobileNavigation{
    display: none;

}

.NavBar .Navigation{
}

@media (max-width: 600px) {
    .firmenname{
        display: none;
    }
}

@media (max-width: 750px) {

    .NavBar .Navigation {
        display: none;
    }
    .NavBar .MobileNavigation{
        display: flex;
        align-items: center;

    }
    .NavBar .MobileNavigation .Hamburger{
        position: absolute;
        right: 5%;
        cursor: pointer;
    }
    .NavBar ul{
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 40px;
        right: 0;
        background: #263238;
        padding: 20px;
        text-overflow: ellipsis;
    }
}